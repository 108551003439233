<template>
    <!-- /test/app-table/product-orders -->
    <div class="page-test-app-table-product-orders container">
        <div class="row">
            <div class="col-12">
                <app-table
                    class="product-orders-table"

                    title="Product Orders"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"

                    @details="onDetails"
                    @refresh="onRefresh"
                ></app-table>
            </div>
        </div>
    </div>
</template>

<script>
import appTable from '@/components/app-table'

export default {
    components: {
        appTable,
    },

    data() {
        return {
            customer_uuid: 'ceee8826-692b-49be-ab1f-4aac7f48d3a1',

            cols: [
                { key: 'date',        title: 'Date',                                                                                 },
                { key: 'description', title: 'Description',                                                                          },
                { key: 'charge',      title: 'Charge',                                                                               },
                { key: 'status',      title: 'Status',                                          type: 'status',                      },

                // { key: 'details',     title: 'Details',     action: 'details', icon: 'invoice',                                      },
                // { key: 'refresh',     title: 'Refresh',     action: 'refresh', icon: 'refresh',                                       },

                { key: 'details',     title: 'Details',     action: 'details', icon: 'invoice',                 behavior: 'detached' },
                { key: 'refresh',     title: 'Refresh',     action: 'refresh', icon: 'refresh',                  behavior: 'detached' },

                // { key: 'link',  title: 'Link',  type: 'link',  icon: 'link',                                                           },
                // { key: 'route', title: 'Route', type: 'route', icon: 'link', route: { name: 'customer-details' },                      },

                // { key: 'link',  title: 'Link',  type: 'link',  icon: 'link',                                      behavior: 'detached' },
                // { key: 'route', title: 'Route', type: 'route', icon: 'link', route: { name: 'customer-details' }, behavior: 'detached' },
            ],
            rows: [],

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            const delay_in_ms = 1000
            const charge = 26.65

            this.loading = true

            setTimeout(() => {
                let id = 1

                this.rows = [
                    {
                        id: id++,
                        date: '2014-09-20 03:27:49',
                        description: 'FWA Premium - 2/67 Argyle Street, Herne Bay, Auckland',
                        charge: `$ ${ charge }`,
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },

                        /*
                            details: {
                                disabled: false,
                            },
                            refresh: {
                                disabled: false,
                            },
                        */

                        link: 'https://github.com',
                        route: {
                            params: {
                                uuid: this.customer_uuid,
                            },
                        },
                    },
                    {
                        id: id++,
                        date: '2014-09-20 03:27:49',
                        description: 'FWA Premium - 2/67 Argyle Street, Herne Bay, Auckland',
                        charge: `$ ${ charge }`,
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },

                        /*
                            details: {
                                disabled: false,
                            },
                            refresh: {
                                disabled: false,
                            },
                        */

                        link: 'https://github.com',
                        route: {
                            params: {
                                uuid: this.customer_uuid,
                            },
                        },
                    },
                    {
                        id: id++,
                        date: '2014-09-20 03:27:49',
                        description: 'FWA Premium - 2/67 Argyle Street, Herne Bay, Auckland',
                        charge: `$ ${ charge }`,
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },

                        /*
                            details: {
                                disabled: false,
                            },
                            refresh: {
                                disabled: false,
                            },
                        */

                        link: 'https://github.com',
                        route: {
                            params: {
                                uuid: this.customer_uuid,
                            },
                        },
                    },
                    {
                        id: id++,
                        date: '2014-09-20 03:27:49',
                        description: 'FWA Premium - 2/67 Argyle Street, Herne Bay, Auckland',
                        charge: `$ ${ charge }`,
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },

                        /*
                            details: {
                                disabled: false,
                            },
                            refresh: {
                                disabled: false,
                            },
                        */

                        link: 'https://github.com',
                        route: {
                            params: {
                                uuid: this.customer_uuid,
                            },
                        },
                    },
                    {
                        id: id++,
                        date: '2014-09-20 03:27:49',
                        description: 'FWA Premium - 2/67 Argyle Street, Herne Bay, Auckland',
                        charge: `$ ${ charge }`,
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },

                        /*
                            details: {
                                disabled: false,
                            },
                            refresh: {
                                disabled: false,
                            },
                        */

                        link: 'https://github.com',
                        route: {
                            params: {
                                uuid: this.customer_uuid,
                            },
                        },
                    },
                ]

                this.loading = false
            }, delay_in_ms)
        },

        onDetails(row) {
            console.log('onDetails', JSON.parse(JSON.stringify(row)))

            // this.rows[row.id - 1].details.disabled = true
        },

        onRefresh(row) {
            console.log('onRefresh', JSON.parse(JSON.stringify(row)))

            // this.rows[row.id - 1].refresh.disabled = true
        },
    },
}
</script>

<style lang="scss">
.page-test-app-table-product-orders {
    .product-orders-table {
        margin: 48px 0;

        @include table-cols-width((110px, 350px, 110px, 200px, 80px, 80px), true);

        .cols {
            .col {
                &:nth-child(5) {
                    justify-content: center;
                }

                &:nth-child(6) {
                    justify-content: center;
                }
            }
        }

        .rows {
            .row {
                .col {
                    &:nth-child(5) {
                        justify-content: center;

                        .btn-table-action {
                            &.action-link,
                            &.action-remove {
                                margin-right: auto;
                            }
                        }
                    }

                    &:nth-child(6) {
                        justify-content: center;

                        .btn-table-action {
                            &.action-link,
                            &.action-remove {
                                margin-right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-test-app-table-product-orders {
        .product-orders-table {
            @include table-cols-width((90px, 160px, 80px, 180px, 50px, 50px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-test-app-table-product-orders {
        .product-orders-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }
    }
}
</style>